<template>
  <div class="white black--text">
    <v-row>
      <v-col
        cols="12"
        offset-md="2"
        md="8"
        offset-lg="3"
        lg="6"
        offset-xl="3"
        xl="6"
                class="mt-12"
      >
        <div id="howToDescription">
          <heading1Section
            color="black"
            :dense="true"
            :text="$t('lp_h1_events_sharing')"
          />
          <heading2Section
            color="black"
            :dense="true"
            :text="$t('lp_h2_events_sharing')"
            style="margin-bottom: 2rem"
          />

          <quote class="quote" :text="$t('lp_quote_events_sharing')" />

          <paragraph class="paragraph" :text="$t('lp_p_events_sharing_1')" />

          <div class="text-center">
            <img
              data-aos="fade-right"
              draggable="false"
              :src="
                require('@/assets/graphics/landing/pictures/howto/sharing/sharing_01.png')
              "
              style="max-height: 140px; height: 30vh"
              alt="reading qrcode"
            />
          </div>

          <paragraph class="paragraph" :text="$t('lp_p_events_sharing_2')" />
        </div>
      </v-col>
    </v-row>

    <seeAlso v-if="!wait_seeAlsoBtns" :back_section="back_section" />
    <btnBackHome :back_section="back_section" />
    <!-- <pageFooter height="120px" /> -->
  </div>
</template>

<script>
import heading1Section from "@/components/landings/heading1Section.vue";
import heading2Section from "@/components/landings/heading2Section.vue";
import sectionDescription from "@/components/landings/sectionDescription.vue";
import paragraph from "@/components/landings/paragraph.vue";
import seeAlso from "@/components/landings/blocks/seeAlso.vue";
import btnBackHome from "@/components/landings/btnBackHome.vue";
import cardAsButton from "@/components/landings/cardAsButton.vue";
import appOnMobilePhone from "@/components/landings/appOnMobilePhone.vue";
import "@/styles/landing.css";
import AOS from "aos";
import "aos/dist/aos.css";
import quote from "@/components/landings/quote.vue";
import pageFooter from "@/components/landings/blocks/pageFooter.vue";

export default {
  metaInfo: {
    title: "Condividi e comunica con i tuoi collaboratori",
    meta: [
      {
        name: "description",
        content:
          "Condividi e comunica gli eventi di sicurezza all'interno della tua azienda a tutti i tuoi collaboratori per far si che l'esperienze passate non si ripetano.",
      },
    ],
  },

  props: ["from_section"],
  components: {
    pageFooter,
    heading1Section,
    heading2Section,
    sectionDescription,
    cardAsButton,
    appOnMobilePhone,
    paragraph,
    seeAlso,
    btnBackHome,
    quote,
  },
  data: () => ({
    back_section: null,
    wait_seeAlsoBtns: true,
  }),
  methods: {
    getTranslations(key) {
      return this.$t(key);
    },
  },
  mounted() {
    AOS.init();
    if (this.$route.params.from_section) {
      this.back_section = this.$route.params.from_section;
      console.log("back to:", this.$route.params.from_section);
    }
    setInterval(() => {
      this.wait_seeAlsoBtns = false;
    }, 500);
  },
  created() {},
  computed: {
    // image_list_app_screenshots_events_sharing() {
    //   return [
    //     {
    //       url: require("@/assets/graphics/landing/pictures/howto/event/app_event_01.png"),
    //       alt: this.$t("lp_img_detail_events_sharing_img_title_01"),
    //       title: this.$t("lp_img_detail_events_sharing_img_title_01"),
    //       desc: this.$t("lp_img_detail_events_sharing_img_desc_01"),
    //     },
    //     {
    //       url: require("@/assets/graphics/landing/pictures/howto/event/app_event_02.png"),
    //       alt: this.$t("lp_img_detail_events_sharing_img_title_02"),
    //       title: this.$t("lp_img_detail_events_sharing_img_title_02"),
    //       desc: this.$t("lp_img_detail_events_sharing_img_desc_02"),
    //     },
    //     {
    //       url: require("@/assets/graphics/landing/pictures/howto/event/app_event_03.png"),
    //       alt: this.$t("lp_img_detail_events_sharing_img_title_03"),
    //       title: this.$t("lp_img_detail_events_sharing_img_title_03"),
    //       desc: this.$t("lp_img_detail_events_sharing_img_desc_03"),
    //     },
    //     {
    //       url: require("@/assets/graphics/landing/pictures/howto/event/app_event_04.png"),
    //       alt: this.$t("lp_img_detail_events_sharing_img_title_04"),
    //       title: this.$t("lp_img_detail_events_sharing_img_title_04"),
    //       desc: this.$t("lp_img_detail_events_sharing_img_desc_04"),
    //     },
    //     {
    //       url: require("@/assets/graphics/landing/pictures/howto/event/app_event_05.png"),
    //       alt: this.$t("lp_img_detail_events_sharing_img_title_05"),
    //       title: this.$t("lp_img_detail_events_sharing_img_title_05"),
    //       desc: this.$t("lp_img_detail_events_sharing_img_desc_05"),
    //     },
    //   ];
    // },
  },
  watch: {},
};
</script>

